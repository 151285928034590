import { DateTime } from 'luxon';

import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';
import { Card } from '../../../common/Atoms/Card';
import { MultiButtonCardHeader } from '../../../common/Atoms/MultiButtonCardHeader';
import { ACTIONS_ARTICLE } from '../../constants/links';
import { useForceRecheckAllMutation, useRemoveAllFailedActionsMutation } from '../../services/api/actionApi/action';
import { useGetSelectedOrganisation } from '../../hooks/useGetSelectedOrganisation';
import { CardContent } from '../../../common/Atoms/CardContent';

import { ActionsTable } from './ActionsTable';

export function ActionsTableRoot() {
  const currentOrg = useGetSelectedOrganisation();
  const [recheck, { isLoading: isRechecking }] = useForceRecheckAllMutation();
  const [removeAllActions, { isLoading: isRemovingAllActions }] = useRemoveAllFailedActionsMutation();

  // The controlled state

  function onRecheck() {
    recheck({
      organisationId: currentOrg.id,
    });
  }

  function onRemoveAllActions() {
    removeAllActions({
      organisationId: currentOrg.id,
    });
  }

  return (
    <Card>
      <MultiButtonCardHeader
        title={ (
          <span>
            { `All Actions ` }
            <Paragraph
              as={ `span` }
              variant={ `link` }>
              <a
                href={ ACTIONS_ARTICLE }
                target={ `_blank` }
                rel={ `noopener noreferrer` }
              >
                { `(How Actions Work)` }
              </a>
            </Paragraph>
          </span>
        ) }
        buttons={ [
          {
            buttonText: `Recalculate all pending actions`,
            onClick: onRecheck,
            loading: isRechecking,
            disabled: isRechecking,
            tooltip: `This will take a few minutes to complete. This happens automatically every 4 hours.` + (currentOrg.lastActionCreate ? `(Last Calculation: ${DateTime.fromJSDate(new Date(currentOrg.lastActionCreate)).toRelative()})` : ``),
          },
          {
            buttonText: `Remove all failed actions`,
            onClick: onRemoveAllActions,
            loading: isRemovingAllActions,
            disabled: isRemovingAllActions,
            tooltip: `This will allow them to be recalulated. If the policy conditions still match.`,
          },
        ] }
        buttonsAsDropdownText={ `Manual Operations` }
        noDivider
      />
      <CardContent>
        <ActionsTable />
      </CardContent>
    </Card>
  );
}
