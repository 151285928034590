import { Else, If, Then, When } from "react-if";
import { IPolicy, PolicyDayPeriodType, PolicyIntervalType, PolicyState, getOrdinalSuffix, langauge, policyWeeklyDayIntervalMap, statementTypes, validatePolicySync } from "shared";
import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { ArrowRightIcon, ChevronRightIcon, PauseCircleIcon, PencilIcon, PlayCircleIcon, StopCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router";

import { renderDayIndex, renderDescription } from "../../../../lib/helper";
import { Paragraph } from "../../../../../common/Atoms/Typography/Paragraph";
import { Badge } from "../../../../../common/Atoms/Badge";
import { open } from "../../../../slices/policyDrawer";
import { usePolicies } from "../../../../hooks/usePolicies";
import { classNames } from "../../../../../common/lib/classNames";
import { useUpdatePolicyMutation } from "../../../../services/api/policyApi/policy";
import { ThreeWayToggle, ToggleOption } from "../../../../../common/Atoms/ThreeWayToggle";
// import { POLICY_STATE_ARTICLE } from "../../../../constants/links";
import { Heading } from "../../../../../common/Atoms/Typography/Heading";
import { useActionTableModels } from "../../../../hooks/useActions";
import { useCurrentOrgUsers } from "../../../../hooks/useCurrentOrgUsers";

interface ExistingPolicyItemProps {
  policy: IPolicy
}

export function ExistingPolicyItem({ policy }: ExistingPolicyItemProps) {
  const { data: policies } = usePolicies();
  const [updatePolicy, { isLoading: updatePolicyLoading }] = useUpdatePolicyMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentOrgUsers = useCurrentOrgUsers();
  const { setFilterModel, setCurrentTab } = useActionTableModels();

  function onClick() {
    const policyFromCache = policies?.find(p => p.policy.id === policy.id);

    if (!policyFromCache) return;
    dispatch(open(policyFromCache));
  }

  const policyValid = useMemo(() => {
    let policyValidVar = true;

    try {
      validatePolicySync(policy);
    }
    catch (e) {
      console.warn(e);
      policyValidVar = false;
    }

    return policyValidVar;
  }, [policy]);

  const groupIds = useMemo(() => {
    return policies?.find(p => p.policy.id === policy.id)?.groupIds || [];
  }, [ policies, policy]);

  function onStateChange(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, newState: string) {
    e.preventDefault();
    e.stopPropagation();

    const policyFromCache = policies?.find(p => p.policy.id === policy.id);

    if (!policyFromCache) return;

    updatePolicy({
      ...policyFromCache,
      policy: {
        ...policyFromCache.policy,
        state: newState as PolicyState,
      },
      organisationId: policy.organisation_id,
      id: policy.id,
    });
  }

  function onViewActions(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();
    setCurrentTab(null);

    setFilterModel({
      items: [
        {
          field: `policyId`,
          operator: `is`,
          value: policy.id,
          id: Math.round(Math.random() * 1e5),
        },
      ],
    });

    navigate(`/${policy.organisation_id}/actions?persist=true`);
  }

  const stateOptions: ToggleOption[] = useMemo(() => {
    const invalidTooltip = `Policy is incomplete. Please update policy first`;

    return [
      {
        icon: PlayCircleIcon,
        tooltip: policyValid ? langauge.policy.state.active.tooltip : invalidTooltip,
        value: PolicyState.ACTIVE,
        color: `green`,
        disabled: !policyValid || policy?.state === PolicyState.ACTIVE,
      },
      {
        tooltip: policyValid ? langauge.policy.state.approval.tooltip : invalidTooltip,
        value: PolicyState.APPROVAL,
        color: `orange`,
        icon: PauseCircleIcon,
        disabled: !policyValid || policy?.state === PolicyState.APPROVAL,
      },
      {
        tooltip: langauge.policy.state.inactive.tooltip,
        value: PolicyState.INACTIVE,
        color: `red`,
        icon: StopCircleIcon,
        disabled: !policyValid || policy?.state === PolicyState.INACTIVE,
      },
    ];
  }, [policyValid, policy?.state]);

  return (
    <div key={ policy.id }
      className={ classNames(
        `text-left border border-gray-100 p-3 rounded-md shadow-md w-full cursor-pointer`,
        policyValid ? `` : `opacity-75 border-red-500 border-2`,
      ) }
      onClick={ onClick }
    >
      <div className={
        `grid grid-cols-12 gap-2`
      }>
        { /* Column 1 - Day index etc */ }
        <div className={
          `col-span-3 self-center`
        }>
          <If condition={ !statementTypes.includes(policy.policy_type) }>
            <Then>
              { /*  Is Invoice Type */ }
              {
                () => (
                  <>
                    <When condition={ renderDayIndex(policy).day !== `0` }>
                      <p className={ `text-xl` }>
                        { renderDayIndex(policy).day }
                        <span className={ `text-xs` }>
                          {
                            policy.day_period_type === PolicyDayPeriodType.WEEKDAYS ? ` weekday` : ` day`
                          }
                          {
                            renderDayIndex(policy).day === `1` ? `` : `s`
                          }
                        </span>
                      </p>
                    </When>
                    <p className={ `text-md` }>
                      { renderDayIndex(policy).verb }
                    </p>
                    <p className={ `text-xs` }>
                      { ` the ${langauge.policy.targetStatus[policy.target_status] || `due date`}` }
                    </p>
                  </>
                )
              }
            </Then>
            <Else>
              { /* Is statement/interval based */ }
              {
                () => (
                  policy.interval_type === PolicyIntervalType.MONTHLY ? (
                    <>
                      <p className={ `text-xl` }>
                        { `${policy.day_index}${getOrdinalSuffix(policy.day_index || 1)}` }
                      </p>
                      <p className={ `text-xs` }>
                        { `of the month` }
                      </p>
                    </>
                  ) : (
                    <>
                      <p className={ `text-xl` }>
                        { `${policyWeeklyDayIntervalMap[policy.day_index]}` }
                      </p>
                      <p className={ `text-xs` }>
                        { `weekly` }
                      </p>
                    </>
                  )
                )
              }
            </Else>
          </If>
        </div>

        { /* Column 2 Badge, descriptions and edit icon */ }
        <div className={ `col-span-5` }>
          { /* Badge and descriptions */ }

          <Heading variant={ `secondary` }>
            <span>
              <span className={ `flex items-start` }>
                { langauge.policyType[policy.policy_type] }

                <PencilIcon className={ `h-4 w-4 ml-1` } />
              </span>
              <When condition={ !policyValid }>
                <Tooltip title={ `Policy has incomplete settings. Click to edit policy` }>
                  <ExclamationTriangleIcon
                    color={ `red` }
                    className={ `h-5 w-5 text-red-450 inline-block ml-1` }

                  />
                </Tooltip>
              </When>
            </span>
          </Heading>

          { /* Temp warning for legacy users */ }
          <When condition={ groupIds?.length > 1 }>
            <Tooltip title={ `This policy is assigned to multiple groups. Changes will affect all groups.` }>
              <Badge
                color={ `orange` }
                className={ `max-w-fit` }

              >
                { `Multiple Groups` }
              </Badge>
            </Tooltip>
          </When>

          <When condition={ policyValid && policy.only_webhook }>
            <Tooltip title={ `Will not send directly to contacts, will only trigger webhooks` }>
              <Badge
                color={ `orange` }
                className={ `max-w-fit` }

              >
                { `Webhook Only` }
              </Badge>
            </Tooltip>
          </When>

          <Paragraph className={ `mt-1 mb-2` }>
            { policy.title }
          </Paragraph>

          <Paragraph
            variant={ `secondary` }
          >
            { renderDescription(policy, currentOrgUsers).description.split(` | `).map((desc, i) => {
              return (
                <span key={ i }>
                  <ChevronRightIcon className={ `w-3 h-3 inline-block` }/>{ desc }
                  <br />
                </span>
              );
            }) }
          </Paragraph>
          <Paragraph
            variant={ `help` }
          >
            { renderDescription(policy, currentOrgUsers).secondaryDescription }
          </Paragraph>
        </div>

        { /* Column 3 - Policy State */ }
        <div className={ `col-span-4 flex items-center justify-end` }>
          <div className={ `flex flex-col items-end` }>
            <ThreeWayToggle
              className={ `` }
              options={ stateOptions }
              value={ policy.state }
              onChange={ onStateChange }
              loading={ updatePolicyLoading }
            // helpLink={ POLICY_STATE_ARTICLE }
            />

            <Paragraph
              variant={ `link` }
              className={ `text-xs mt-2` }
            >
              { `Edit` }

              <ArrowRightIcon className={ `h-4 w-4 inline-block ml-1` } />
            </Paragraph>

            <Tooltip title={ `View all related actions for this policy` }>
              <a onClick={ onViewActions }>
                <Paragraph
                  variant={ `link` }
                  className={ `text-xs mt-2` }
                >
                  { `View Actions` }

                  <ArrowRightIcon className={ `h-4 w-4 inline-block ml-1` } />
                </Paragraph>
              </a>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}
