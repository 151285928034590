import { PolicyType, IInvalidField, IPolicy, PolicyCalculationType, PolicyState } from 'shared';
import { Case, Switch, When } from 'react-if';
import { useDispatch, useSelector } from 'react-redux';
import { AdjustmentsVerticalIcon, Cog6ToothIcon, RocketLaunchIcon } from '@heroicons/react/24/outline';
import { useMemo, useState } from 'react';
import { BeakerIcon } from '@heroicons/react/20/solid';

import { Button } from '../../../../common/Atoms/Button';
import { Notification } from '../../../../common/Atoms/Notification';
import { Form } from '../../Molecules/Form';
import { usePolicySanityChecks } from '../../../hooks/usePolicySanityChecks';
import { RootState } from '../../../store';
import { updateFormData } from '../../../slices/policyDrawer';
import { LoadingOverlay } from '../../Molecules/LoadingOverlay';
import { ITab, Tabs } from '../../../../common/Atoms/Tabs';
import { Card } from '../../../../common/Atoms/Card';
import { useAdvancedPolicyFields } from '../../../hooks/useAdvancedPolicyFields';
import { env } from '../../../../common/lib/env';

import { PolicyLateFee } from './PolicyTypes/PolicyLateFee';
import { PolicyPromptPaymentDiscount } from './PolicyTypes/PolicyPromptPaymentDiscount';
import { PolicyReminder } from './PolicyTypes/PolicyReminder';
import { PolicySms } from './PolicyTypes/PolicySms';
import { GroupSelector } from './PolicyForm/GroupSelector';
import { AdvancedFields } from './PolicyForm/AdvancedFields/AdvancedFields';
import { PolicyIntervalLateFee } from './PolicyTypes/PolicyStatementLateFee';
import { PolicyStatement } from './PolicyTypes/PolicyStatement';
import { PolicyEscalation } from './PolicyTypes/PolicyEscalation';
import { PolicyTitle } from './PolicyForm/Fields/PolicyTitle';
import { PolicyAICall } from './PolicyTypes/PolicyAICall';
import { PolicySmsNew } from './PolicyTypesNew/PolicySms';
import { PolicyQuote } from './PolicyTypes/PolicyQuote';
import { PolicyTestViewRoot } from './PolicyTestView/PolicyTestViewRoot';

// For the children components
export interface IPolicyProps {
  policy: IPolicy;
  invalids: IInvalidField[];
  handleChange: (obj: { name: string; value: string | number | boolean | Date }) => void;
}

interface IPolicyItemProps {
  onSave: () => void;
  onSaveSetLive: () => void;
  onDelete: () => void;
  invalids: IInvalidField[];
  loading?: boolean;
}

export type HandleChange = (obj: { name: string; value: string | number | boolean | Date | number[] }, isGroupsChange?: boolean) => void;

export function PolicyItem({
  onSave,
  onDelete,
  invalids,
  loading,
  onSaveSetLive,
}: IPolicyItemProps) {
  // Hooks
  const dispatch = useDispatch();
  const drawerProps = useSelector((s: RootState) => s.policyDrawer);
  const { policyFormData, formDataDirty } = drawerProps;
  const [tab, setTab] = useState<`main` | `advanced` | `test`>(`main`);
  const advancedFieldsInUse = useAdvancedPolicyFields(policyFormData?.policy);

  const tabs: ITab[] = useMemo(() => {
    return [
      {
        name: `Policy Configuration`,
        onClick: () => setTab(`main`),
        href: `main`,
        icon: Cog6ToothIcon,
        current: tab === `main`,
      },
      {
        name: `More Options & Filters`,
        onClick: () => setTab(`advanced`),
        href: `advanced`,
        icon: AdjustmentsVerticalIcon,
        current: tab === `advanced`,
        badge: advancedFieldsInUse.length ? {
          text: `${advancedFieldsInUse.length}`,
          color: `gray`,
        } : undefined,
      },
      {
        name: `Test Policy`,
        onClick: () => setTab(`test`),
        href: `test`,
        icon: RocketLaunchIcon,
        current: tab === `test`,
      },
    ];
  }, [tab, advancedFieldsInUse]);

  const warnings = usePolicySanityChecks(policyFormData?.policy);

  if (!policyFormData || !policyFormData.policy) {
    return null;
  }

  const { policy } = policyFormData;

  const handleChange: HandleChange = (e, isGroupsChange) => {
    const update = isGroupsChange ?
    // Only update group IDS
      {
        ...policyFormData,
        groupIds: e.value,
      } :
      // Otherwise update the policy object
      {
        ...policyFormData,
        policy: {
          ...policyFormData.policy,
          [e.name]: e.value,
        },
      };

    if (update.policy.policy_type === PolicyType.STATEMENT_LATE_FEE) {
      if (update.policy.calculation_type === PolicyCalculationType.NOT_APPLICABLE) {
        update.policy.percent = 0;
      }
    }

    dispatch(updateFormData(update));
  };

  // Crude temp feature flag
  const NEW_POLICY_LAYOUT = env.newPolicyLayout;

  if (NEW_POLICY_LAYOUT) {
    return (
      <LoadingOverlay
        loading={ loading }>
        <Form>
          <div
            className={ `flex flex-col justify-between h-full` }
            data-tour={ `policy-form` }
          >
            <GroupSelector
              policyFormData={ policyFormData }
              handleChange={ handleChange }
            />

            <PolicyTitle
              policy={ policy }
              invalids={ invalids }
              handleChange={ handleChange }
            />

            <Card className={ `space-y-6 mb-6 shadow-md rounded-lg mt-4` }>
              <When condition={ policy.policy_type === PolicyType.AI_CALL }>
                <Notification
                  type={ `pro` }
                  icon={ BeakerIcon }
                >
                  { `You have early access to this feature. It may not be perfect so please let us know if you have any feedback!` }
                </Notification>
              </When>

              <When condition={ tab === `main` }>
                <Switch>
                  <Case condition={ policy.policy_type === PolicyType.LATE_FEE }>
                    <PolicyLateFee
                      policy={ policyFormData.policy }
                      handleChange={ handleChange }
                      invalids={ invalids }
                    />
                  </Case>
                  <Case condition={ policy.policy_type === PolicyType.STATEMENT_LATE_FEE }>
                    <PolicyIntervalLateFee
                      policy={ policyFormData.policy }
                      handleChange={ handleChange }
                      invalids={ invalids }
                    />
                  </Case>
                  <Case condition={ policy.policy_type === PolicyType.DISCOUNT }>
                    <PolicyPromptPaymentDiscount
                      policy={ policyFormData.policy }
                      handleChange={ handleChange }
                      invalids={ invalids }
                    />
                  </Case>
                  <Case condition={ policy.policy_type === PolicyType.REMINDER }>
                    <PolicyReminder
                      policy={ policyFormData.policy }
                      handleChange={ handleChange }
                      invalids={ invalids }
                    />
                  </Case>
                  <Case condition={ policy.policy_type === PolicyType.SMS }>
                    <PolicySmsNew
                      policy={ policyFormData.policy }
                      handleChange={ handleChange }
                      invalids={ invalids }
                    />
                  </Case>
                  <Case condition={ policy.policy_type === PolicyType.STATEMENT }>
                    <PolicyStatement
                      policy={ policyFormData.policy }
                      handleChange={ handleChange }
                      invalids={ invalids }
                    />
                  </Case>
                  <Case condition={ policy.policy_type === PolicyType.ESCALATION }>
                    <PolicyEscalation
                      policy={ policyFormData.policy }
                      handleChange={ handleChange }
                      invalids={ invalids }
                    />
                  </Case>
                  <Case condition={ policy.policy_type === PolicyType.AI_CALL }>
                    <PolicyAICall
                      policy={ policyFormData.policy }
                      handleChange={ handleChange }
                      invalids={ invalids }
                    />
                  </Case>
                  <Case condition={ policy.policy_type === PolicyType.QUOTE_EMAIL }>
                    <PolicyQuote
                      policy={ policyFormData.policy }
                      handleChange={ handleChange }
                      invalids={ invalids }
                    />
                  </Case>
                </Switch>
              </When>
              <When condition={ tab === `advanced` }>
                <AdvancedFields
                  policyFormData={ policyFormData.policy }
                  handleChange={ handleChange }
                  invalids={ invalids }
                />
              </When>

              <When condition={ tab === `test` }>
                <PolicyTestViewRoot
                  policyFormData={ policyFormData }
                  unsavedChanges={ formDataDirty }
                />
              </When>
            </Card>

            <When condition={ warnings.length }>
              <div className={ `space-y-2` }>
                {
                  warnings.map(warning => (
                    <Notification type={ `warning` }
                      key={ warning }>
                      { warning }
                    </Notification>
                  ))
                }
              </div>
            </When>
            <Switch>
              <Case condition={ invalids.length }>
                <div className={ `transition ease-in-out duration-250` }>
                  <Notification
                    type={ `error` }
                    title={ `Errors found in policy:` }
                  >
                    <span className={ `flex flex-col` }>
                      {
                        invalids.map(err => (
                          <span key={ err.message }>
                            { err.message }
                          </span>
                        ))
                      }
                    </span>
                  </Notification>
                </div>
              </Case>
            </Switch>

            <When condition={ policy.policy_type === PolicyType.SMS }>
              <Notification
                type={ `info` }
                className={ `mt-6` }>
                { `SMS messages are charged at $0.10 each` }
              </Notification>
            </When>

            <When condition={ policy.policy_type === PolicyType.AI_CALL }>
              <Notification
                type={ `info` }
                className={ `mt-6` }>
                { `AI Calls are charged at 1.00 credit per minute deducted from your balance.` }
              </Notification>
            </When>
            <div className={ `flex justify-start space-x-6 mt-2 sm:mt-6` }>
              <Button
                onClick={ onDelete }
                color={ `red` }
              >
                { `Delete Policy` }
              </Button>

              <Button
                onClick={ onSave }
                disabled={ !formDataDirty }
                data-tour={ `policy-form-save` }
              >
                { `Save & Close` }
              </Button>

              <When condition={ policy.state !== PolicyState.ACTIVE }>
                <Button
                  onClick={ onSaveSetLive }
                  color={ `green` }
                  disabled={ !formDataDirty }
                  data-tour={ `policy-form-save` }
                >
                  { `Save & Set Live` }
                </Button>
              </When>
            </div>
          </div>
        </Form>
      </LoadingOverlay>
    );
  }

  return (
    <LoadingOverlay
      loading={ loading }>
      <Form>
        <div
          className={ `flex flex-col justify-between h-full` }
          data-tour={ `policy-form` }
        >
          <GroupSelector
            policyFormData={ policyFormData }
            handleChange={ handleChange }
          />

          <PolicyTitle
            policy={ policy }
            invalids={ invalids }
            handleChange={ handleChange }
          />

          <Card className={ `space-y-6 mb-6 shadow-md rounded-lg mt-4` }>
            <Tabs
              navigational={ false }
              tabs={ tabs }
              className={ `pb-0` }
            />

            { /* Early Access */ }
            <When condition={ policy.policy_type === PolicyType.AI_CALL }>
              <Notification
                type={ `pro` }
                icon={ BeakerIcon }
              >
                { `You have early access to this feature. It may not be perfect so please let us know if you have any feedback!` }
              </Notification>
            </When>

            <When condition={ tab === `main` }>
              <Switch>
                <Case condition={ policy.policy_type === PolicyType.LATE_FEE }>
                  <PolicyLateFee
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.STATEMENT_LATE_FEE }>
                  <PolicyIntervalLateFee
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.DISCOUNT }>
                  <PolicyPromptPaymentDiscount
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.REMINDER }>
                  <PolicyReminder
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.SMS }>
                  <PolicySms
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.STATEMENT }>
                  <PolicyStatement
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.ESCALATION }>
                  <PolicyEscalation
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.AI_CALL }>
                  <PolicyAICall
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
                <Case condition={ policy.policy_type === PolicyType.QUOTE_EMAIL }>
                  <PolicyQuote
                    policy={ policyFormData.policy }
                    handleChange={ handleChange }
                    invalids={ invalids }
                  />
                </Case>
              </Switch>
            </When>
            <When condition={ tab === `advanced` }>
              <AdvancedFields
                policyFormData={ policyFormData.policy }
                handleChange={ handleChange }
                invalids={ invalids }
              />
            </When>

            <When condition={ tab === `test` }>
              <PolicyTestViewRoot
                policyFormData={ policyFormData }
                unsavedChanges={ formDataDirty }
              />
            </When>
          </Card>

          <When condition={ warnings.length }>
            <div className={ `space-y-2` }>
              {
                warnings.map(warning => (
                  <Notification type={ `warning` }
                    key={ warning }>
                    { warning }
                  </Notification>
                ))
              }
            </div>
          </When>
          <Switch>
            <Case condition={ invalids.length }>
              <div className={ `transition ease-in-out duration-250 mb-4` }>
                <Notification
                  type={ `error` }
                  title={ `Errors found in policy:` }
                >
                  <span className={ `flex flex-col` }>
                    {
                      invalids.map(err => (
                        <span key={ err.message }>
                          { err.message }
                        </span>
                      ))
                    }
                  </span>
                </Notification>
              </div>
            </Case>
          </Switch>

          <When condition={ policy.policy_type === PolicyType.SMS }>
            <Notification
              type={ `info` }
              className={ `mt-6` }>
              { `SMS messages are charged at $0.10 each` }
            </Notification>
          </When>

          <When condition={ policy.policy_type === PolicyType.QUOTE_EMAIL }>
            <Notification
              type={ `pro` }
              icon={ BeakerIcon }
              border
            >
              { `This is a brand new feature! Please let us know if you have any feedback or requests!` }
            </Notification>
          </When>

          <When condition={ policy.policy_type === PolicyType.AI_CALL }>
            <Notification
              type={ `info` }
              className={ `mt-6` }>
              { `AI Calls are charged at 1.00 credit per minute deducted from your balance.` }
            </Notification>
          </When>
          <div className={ `flex justify-start space-x-6 mt-2 sm:mt-6` }>
            <Button
              onClick={ onDelete }
              color={ `red` }
            >
              { `Delete Policy` }
            </Button>

            <Button
              onClick={ onSave }
              disabled={ !formDataDirty }
              data-tour={ `policy-form-save` }
            >
              { `Save & Close` }
            </Button>

            <When condition={ policy.state !== PolicyState.ACTIVE }>
              <Button
                onClick={ onSaveSetLive }
                color={ `green` }
                data-tour={ `policy-form-save` }
              >
                { `Save & Set Live` }
              </Button>
            </When>

          </div>
        </div>
      </Form>
    </LoadingOverlay>
  );
}
